@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
/* @import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css"; */

@layer base {
  body {
    @apply bg-gray-200;
  }
  .dropdown {
    @apply w-full cursor-pointer;
  }
  .dropdown-btn {
    @apply flex p-2 items-center border-2 rounded-md;
  }
  .dropdown-icon-primary {
    @apply text-xl mr-4 text-green-300;
  }
  .dropdown-icon-secondary {
    @apply text-xl text-green-300 ml-auto;
  }
  .dropdown-menu {
    @apply p-6 text-sm space-y-4 shadow-md bg-white absolute w-full z-10 list-none rounded-b-lg;
  }
}
